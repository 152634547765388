* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 2;
  font-size: 2.5rem;
  font-family: "Lemonada", cursive;
  color: rgb(12, 102, 162);
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

header {
  height: 32vh;
  background-color: #e0ffff;
  background-image: url("./images/header_wave.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
}

.container {
  display: flex;
}

.logo {
  width: 120px;
  height: 110px;
}

.item-card {
  margin: 1%;
}

h1 {
  font-family: "Lobster", cursive;
  color: #000000;
}

h2 {
  font-family: "Satisfy", cursive;
  margin-top: -4%;
  margin-left: -2%;
  color: #000000;
}

h3 {
  margin-left: 2%;
  margin-top: 2%;
}

h6 {
  margin-left: 2%;
  margin-top: 2%;
}

.text-box {
  width: 80%;
  font-size: 2vw;
}

.search {
  margin-left: 5%;
  margin-top: 2%;
  font-size: 2.5rem;
}

nav {
  display: flex;
  margin-left: 5%;
  margin-right: 60%;
}

.textbox {
  margin-top: -10%;
  margin-left: 15%;
}

.active {
  color: #031d2c;
  text-decoration: none;
  background-color: #ffdd6e;
  padding: 0 3%;
  font-size: 1.6rem;
  height: 2%;
}

nav a {
  color: #000000;
  text-decoration: none;
  background-color: #69c8ff;
  padding: 0 3%;
  font-size: 1.6rem;
  height: 2%;
}

nav a:hover {
  background-color: #f7fffd;
  color: #000000;
  animation-name: navhover;
  animation-duration: 6s;
  animation-iteration-count: 10;
}

.centerSection {
  margin: 5%;
  justify-content: center;
}

.centerSection img {
  width: 100%;
  object-fit: contain;
}

.medium {
  width: 10%;
  margin: 0 auto;
  margin-left: 1%;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 2rem;
  text-align: center;
  color: #000000;
}

.productPage {
  display: flex;
  flex-direction: row;
}

.sectionStyle {
  width: 40%;
  margin-top: 5%;
  margin-left: 3%;
  font-size: 2.3vw;
  /* border: #f50a0a solid 10px; */
}

.image {
  width: 50%;
  object-fit: contain;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image img {
  width: 80%;
  object-fit: contain;
}

.arrow {
  align-self: center;
  justify-self: center;
  text-decoration: none;
  color: rgb(12, 102, 162);
  font-size: 5rem;
}

.disabled {
  color: lightgrey;
}

.buyPrints {
  color: #012555;
  background-color: #ffdd6e;
  margin-top: -5%;
  border-radius: 50%;
  font-size: 3.6rem;
  text-decoration: none;
}

.buyPrints:hover {
  background-color: #69c8ff;
}

.title-image-mobile {
  display: none;
}

.gallery {
  display: flex;
  margin-top: 3%;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.pic {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 10%;
  margin: 1%;
}

.pic img {
  height: 20vw;
}

footer {
  background-color: #69c8ff;
  padding-top: 5%;
  margin-top: 5%;
}

.ender {
  background-image: linear-gradient(#69c8ff, white, #69c8ff);
  display: inline-block;
  width: 100%;
  text-align: center;
  color: black;
}

.ender p {
  font-size: 1.5rem;
}

.ender h2 {
  margin: 0;
}

.icons {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}

.icons img {
  margin: 2px;
}

@media (max-width: 800px) {
  .logo {
    width: 20vw;
    height: 20vw;
  }

  .text-box {
    width: 80%;
    font-size: 3vw;
  }

  .pic img {
    width: 30vw;
    height: 30vw;
  }

  .arrow {
    font-size: 5rem;
  }

  .disabled {
    font-size: 5rem;
  }

  .sectionStyle {
    font-size: 2.4rem;
  }
}

@media (max-width: 500px) {
  .logo {
    width: 30vw;
  }

  .text-box {
    width: 80%;
    font-size: 3.5vw;
  }

  header {
    height: 25vh;
  }

  nav {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 2%;
  }

  nav a {
    width: 30%;
    text-align: center;
  }

  .pic {
    font-size: 1.4rem;
  }

  .pic img {
    width: 40vw;
    height: 40vw;
  }

  .centerSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    margin: 0;
  }

  .productPage {
    flex-direction: column;
  }

  .centerSection img {
    width: 100%;
  }

  .arrow {
    font-size: 5rem;
  }

  .disabled {
    font-size: 5rem;
  }

  .buyPrints {
    font-size: 2.6rem;
  }

  .sectionStyle {
    width: 90%;
    font-size: 4vw;
    text-align: center;
    padding-left: 0;
  }

  .title-image {
    display: none;
  }

  .title-image-mobile {
    display: flex;
  }
}

@media (max-width: 400px) {
  .logo {
    width: 30vw;
  }

  .text-box {
    width: 80%;
    font-size: 3.5vw;
  }

  header {
    height: 25vh;
  }

  nav {
    display: flex;
    align-items: center;
  }

  nav a {
    width: 30%;
    text-align: center;
  }

  .pic {
    font-size: 1.4rem;
  }

  .pic img {
    width: 40vw;
    height: 40vw;
  }

  .centerSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    margin: 0;
  }

  .centerSection img {
    width: 100%;
  }

  .arrow {
    font-size: 4rem;
  }

  .buyPrints {
    font-size: 2.6rem;
  }

  .sectionStyle {
    width: 90%;
    font-size: 4vw;
    text-align: center;
    padding-left: 0;
  }

  .title-image {
    display: none;
  }

  .title-image-mobile {
    display: flex;
  }
}

@media (max-width: 300px) {
  .logo {
    width: 40vw;
  }

  .text-box {
    width: 80%;
    font-size: 4vw;
    margin-top: 10%;
  }

  header {
    height: 35vh;
  }

  nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0;
    margin-bottom: 2%;
  }

  nav a {
    width: 35%;
    text-align: center;
    margin-top: 2%;
  }

  .pic {
    font-size: 1.4rem;
  }

  .pic img {
    width: 40vw;
    height: 40vw;
  }

  .centerSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    margin: 0;
  }

  .centerSection img {
    width: 100%;
  }

  .arrow {
    font-size: 3rem;
  }

  .buyPrints {
    font-size: 2.6rem;
  }

  .sectionStyle {
    width: 90%;
    font-size: 4vw;
    text-align: center;
    padding-left: 0;
  }

  .search {
    margin-left: 0;
  }

  .title-image {
    display: none;
  }

  .title-image-mobile {
    display: flex;
  }
}
